<template>
    <k-layout class="tw-bg-white">
        <template #default>
            <k-container class="tw-py-20">
                <template #default>
                    <div class="tw-w-full tw-mx-auto tw-max-w-4xl tw-px-2">
                        <h1 class="tw-text-2xl tw-text-center" v-html="$t('dictionary.title')" />

                        <div class="tw-flex tw-flex-col sm:tw-flex-row tw-space-y-2 sm:tw-space-y-0 tw-mt-4">
                            <input
                                type="text"
                                v-model="search"
                                class="tw-bg-gray-100 tw-w-full sm:tw-rounded-l-sm tw-p-4 tw-leading-none"
                                :placeholder="$t('dictionary.inputs.enter_search_name')"
                            />
                            <k-button>
                                {{ $t('dictionary.actions.look_up') }}
                            </k-button>
                        </div>

                        <div>
                            <p class="tw-text-sm tw-ml-4 tw-mt-4">
                                <span class="tw-font-bold">{{ $t('dictionary.popular') }}: </span>
                                <template v-for="(item, idx) in popular">
                                    <span :key="item">
                                        <k-link :to="{ name: 'dictionary-detail', params: { id: item } }"> {{ item }} </k-link>
                                        <span v-if="idx < popular.length - 1">, </span>
                                    </span>
                                </template>
                            </p>
                        </div>

                        <div class="tw-mt-16">
                            <ul class="tw-divide-y tw-divide-gray-200 tw-p-0">
                                <li v-for="technologyName in technologies" :key="technologyName">
                                    <k-link
                                        :to="{ name: 'dictionary-detail', params: { id: technologyName } }"
                                        class="tw-p-4 tw-text-gray-700 hover:tw-bg-gray-50 tw-block tw-w-full"
                                    >
                                        {{ technologyName }}
                                    </k-link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </template>
            </k-container>
        </template>
    </k-layout>
</template>

<script>
import { Fzf } from "fzf";

export default {
    data() {
        return {
            search: "",
            popular: ["JavaScript", "Java", "Spring"]
        };
    },
    computed: {
        allTechnologiesNames() {
            return this.$store.getters["ENUMS/allTechnologiesNames"];
        },
        fzf() {
            return new Fzf(this.allTechnologiesNames || []);
        },
        technologies() {
            if (this.search) {
                const entries = this.fzf.find(this.search);
                return entries.map(entry => entry.item);
            } else {
                return this.allTechnologiesNames;
            }
        }
    }
};
</script>
